<template>
  <div class="centered-content">
    <h1>404</h1>
    <p>Oops! Something is wrong.</p>

    <router-link class="router-link" to="/">Go Home</router-link>
  </div>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout'
export default {
  name: 'NotFound',
  created() {
    this.$emit(`update:layout`, PublicLayout)
  }
}
</script>

<style scoped>
.centered-content {
  background-color: #007aff;
  color: #fff;
  font-size: 100%;
  line-height: 1.5;
  text-align: center;
}
h1 {
  text-align: center;
  color: #fff;
  font-size: 6rem;
  font-weight: 100;
  text-shadow: #0062cc 1px 1px, #0062cc 2px 2px, #0062cc 3px 3px,
    #0062cd 4px 4px, #0062cd 5px 5px, #0062cd 6px 6px, #0062cd 7px 7px,
    #0062ce 8px 8px, #0063ce 9px 9px, #0063ce 10px 10px, #0063ce 11px 11px,
    #0063cf 12px 12px, #0063cf 13px 13px, #0063cf 14px 14px, #0063cf 15px 15px,
    #0063d0 16px 16px, #0064d0 17px 17px, #0064d0 18px 18px, #0064d0 19px 19px,
    #0064d1 20px 20px;
}
.router-link {
  color: #fff;
  margin-bottom: 60px !important;
  display: inline-block;
  padding: 5px 20px;
  border: 1px solid #fff;
  border-radius: 20px;
}
</style>
